import React, { useContext, useEffect } from "react";
import { View, FlatList, TouchableHighlight } from "react-native";
import { Text, ListItem, Avatar, ThemeContext, BottomSheet } from "@rneui/themed";
import { StackScreenProps } from "@react-navigation/stack";
import { GroupsTabParamList } from "../../navigation/GroupsNavigator";
import { GroupInvite } from "../../API";
import { useStyles } from "../../styles";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { GraphQLResult } from "@aws-amplify/api";
import { PlayerContext } from "../../contexts";

type Props = StackScreenProps<GroupsTabParamList, "My Invites">;

export function Invites({ navigation, route }: Props) {
  const { theme } = useContext(ThemeContext);
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const globalStyles = useStyles(theme);
  const [groupInvites, setGroupInvites] = React.useState<GroupInvite[]>();
  const [groupName, setGroupName] = React.useState<string>();
  const playerDetails = React.useContext(PlayerContext);

  const onPress = (selectedGroupName: string) => {
    setGroupName(selectedGroupName);
    setIsVisible(true);
  };

  const onAcceptPress = async () => {
    const result = (await API.graphql(
      graphqlOperation(mutations.acceptGroupInvite, {
        groupName: groupName,
      })
    )) as GraphQLResult<any>;
    await getInvites();
    await playerDetails?.updatePlayer();
    setIsVisible(false);
  }

  const getInvites = async () => {
    const result = (await API.graphql(
      graphqlOperation(queries.listPlayerInvites, {})
    )) as GraphQLResult<any>;

    setGroupInvites(result.data?.listPlayerInvites?.items || []);
  };

  useEffect(() => {
    if (groupInvites === undefined) {
      getInvites();
    }
  }, [groupInvites]);

  function acronym(text: string): string {
    return text
      .split(/\s/)
      .reduce((accumulator, word) => accumulator + word.charAt(0), "");
  }

  const renderItem = ({ item }: { item: GroupInvite }) => (
    <ListItem
      pad={20}
      Component={TouchableHighlight}
      containerStyle={[
        { flex: 1, alignItems: "center" },
        globalStyles.listItem,
      ]}
      onPress={() => onPress(item.groupName)}
    >
      <View>
        <Avatar
          size={64}
          title={acronym(item.groupName).substring(0, 2)}
          rounded
          containerStyle={{ backgroundColor: theme.colors.black }}
          titleStyle={{ color: theme.colors.white }}
        />
      </View>
      <ListItem.Content>
        <View
          style={{
            flex: 1,
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <ListItem.Title>
            <Text h3 adjustsFontSizeToFit={true} numberOfLines={1}>
              {item.groupName}
            </Text>
          </ListItem.Title>
        </View>
      </ListItem.Content>
    </ListItem>
  );

  return (
    <View style={globalStyles.container}>
      <FlatList
        data={groupInvites || []}
        renderItem={renderItem}
        style={{ flex: 1, alignSelf: "center", width: "100%", maxWidth: 600 }}
      ></FlatList>
      <BottomSheet modalProps={{}} isVisible={isVisible} containerStyle={{marginBottom: 10}}>
        <ListItem
          key="Accept"
          onPress={() => onAcceptPress()}
        >
          <ListItem.Content>
            <ListItem.Title>Accept</ListItem.Title>
          </ListItem.Content>
        </ListItem>
        <ListItem
          key="Decline"
        >
          <ListItem.Content>
            <ListItem.Title>Decline</ListItem.Title>
          </ListItem.Content>
        </ListItem>
        <ListItem
          key="Cancel"
          containerStyle={{backgroundColor: theme.colors.error}}
          onPress={() => setIsVisible(false)}
        >
          <ListItem.Content>
            <ListItem.Title>Cancel</ListItem.Title>
          </ListItem.Content>
        </ListItem>
      </BottomSheet>
    </View>
  );
}
