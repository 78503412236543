/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGroup = /* GraphQL */ `
  mutation CreateGroup($name: String!) {
    createGroup(name: $name) {
      name
      ownerSub
      owner {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
          }
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            player {
              sub
              username
              email
            }
          }
        }
        groups {
          items {
            playerSub
            player {
              sub
              username
              email
            }
            groupName
            group {
              name
              ownerSub
            }
          }
          nextToken
          startedAt
        }
      }
      scoresOverall {
        nextToken
        startedAt
        items {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
          }
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
        }
      }
      players {
        items {
          playerSub
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
          groupName
          group {
            name
            ownerSub
            owner {
              sub
              username
              email
            }
            scoresOverall {
              nextToken
              startedAt
            }
            players {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createGroupPlayer = /* GraphQL */ `
  mutation CreateGroupPlayer($groupName: String!, $playerSub: String!) {
    createGroupPlayer(groupName: $groupName, playerSub: $playerSub) {
      playerSub
      player {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
          }
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            player {
              sub
              username
              email
            }
          }
        }
        groups {
          items {
            playerSub
            player {
              sub
              username
              email
            }
            groupName
            group {
              name
              ownerSub
            }
          }
          nextToken
          startedAt
        }
      }
      groupName
      group {
        name
        ownerSub
        owner {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            distribution {
              one
              two
              three
              four
              five
              six
              misses
            }
            player {
              sub
              username
              email
            }
          }
          scoreHistory {
            nextToken
            startedAt
            items {
              playerSub
              number
              attemptNumber
              hardMode
            }
          }
          groups {
            items {
              playerSub
              groupName
            }
            nextToken
            startedAt
          }
        }
        scoresOverall {
          nextToken
          startedAt
          items {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            distribution {
              one
              two
              three
              four
              five
              six
              misses
            }
            player {
              sub
              username
              email
            }
          }
        }
        players {
          items {
            playerSub
            player {
              sub
              username
              email
            }
            groupName
            group {
              name
              ownerSub
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createPlayer = /* GraphQL */ `
  mutation CreatePlayer($username: String!) {
    createPlayer(username: $username) {
      sub
      username
      email
      scoreOverall {
        playerSub
        number
        average
        rounds
        currentStreak
        maxStreak
        distribution {
          one
          two
          three
          four
          five
          six
          misses
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            distribution {
              one
              two
              three
              four
              five
              six
              misses
            }
            player {
              sub
              username
              email
            }
          }
          scoreHistory {
            nextToken
            startedAt
            items {
              playerSub
              number
              attemptNumber
              hardMode
            }
          }
          groups {
            items {
              playerSub
              groupName
            }
            nextToken
            startedAt
          }
        }
      }
      scoreHistory {
        nextToken
        startedAt
        items {
          playerSub
          number
          attemptNumber
          hardMode
          attempts {
            one {
              one
              two
              three
              four
              five
            }
            two {
              one
              two
              three
              four
              five
            }
            three {
              one
              two
              three
              four
              five
            }
            four {
              one
              two
              three
              four
              five
            }
            five {
              one
              two
              three
              four
              five
            }
            six {
              one
              two
              three
              four
              five
            }
          }
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
        }
      }
      groups {
        items {
          playerSub
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
          groupName
          group {
            name
            ownerSub
            owner {
              sub
              username
              email
            }
            scoresOverall {
              nextToken
              startedAt
            }
            players {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updatePlayer = /* GraphQL */ `
  mutation UpdatePlayer($username: String!) {
    updatePlayer(username: $username) {
      sub
      username
      email
      scoreOverall {
        playerSub
        number
        average
        rounds
        currentStreak
        maxStreak
        distribution {
          one
          two
          three
          four
          five
          six
          misses
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            distribution {
              one
              two
              three
              four
              five
              six
              misses
            }
            player {
              sub
              username
              email
            }
          }
          scoreHistory {
            nextToken
            startedAt
            items {
              playerSub
              number
              attemptNumber
              hardMode
            }
          }
          groups {
            items {
              playerSub
              groupName
            }
            nextToken
            startedAt
          }
        }
      }
      scoreHistory {
        nextToken
        startedAt
        items {
          playerSub
          number
          attemptNumber
          hardMode
          attempts {
            one {
              one
              two
              three
              four
              five
            }
            two {
              one
              two
              three
              four
              five
            }
            three {
              one
              two
              three
              four
              five
            }
            four {
              one
              two
              three
              four
              five
            }
            five {
              one
              two
              three
              four
              five
            }
            six {
              one
              two
              three
              four
              five
            }
          }
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
        }
      }
      groups {
        items {
          playerSub
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
          groupName
          group {
            name
            ownerSub
            owner {
              sub
              username
              email
            }
            scoresOverall {
              nextToken
              startedAt
            }
            players {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createScore = /* GraphQL */ `
  mutation CreateScore($score: String!) {
    createScore(score: $score) {
      playerSub
      number
      average
      rounds
      currentStreak
      maxStreak
      distribution {
        one
        two
        three
        four
        five
        six
        misses
      }
      player {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
          }
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            player {
              sub
              username
              email
            }
          }
        }
        groups {
          items {
            playerSub
            player {
              sub
              username
              email
            }
            groupName
            group {
              name
              ownerSub
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createGroupPost = /* GraphQL */ `
  mutation CreateGroupPost($input: GroupPostInput!) {
    createGroupPost(input: $input) {
      postId
      groupName
      playerSub
      player {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
          }
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            player {
              sub
              username
              email
            }
          }
        }
        groups {
          items {
            playerSub
            player {
              sub
              username
              email
            }
            groupName
            group {
              name
              ownerSub
            }
          }
          nextToken
          startedAt
        }
      }
      contentType
      content
      createdAt
    }
  }
`;
export const createScoreManual = /* GraphQL */ `
  mutation CreateScoreManual($score: ScoreInput!) {
    createScoreManual(score: $score) {
      playerSub
      number
      average
      rounds
      currentStreak
      maxStreak
      distribution {
        one
        two
        three
        four
        five
        six
        misses
      }
      player {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
          }
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            player {
              sub
              username
              email
            }
          }
        }
        groups {
          items {
            playerSub
            player {
              sub
              username
              email
            }
            groupName
            group {
              name
              ownerSub
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createGroupPlayerScoreMonthly = /* GraphQL */ `
  mutation CreateGroupPlayerScoreMonthly(
    $score: GroupPlayerScoreMonthlyInput!
  ) {
    createGroupPlayerScoreMonthly(score: $score) {
      groupName
      playerSub
      month
      averageOverall
      averagePosted
      rounds
      player {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
          }
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            player {
              sub
              username
              email
            }
          }
        }
        groups {
          items {
            playerSub
            player {
              sub
              username
              email
            }
            groupName
            group {
              name
              ownerSub
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createGroupInvite = /* GraphQL */ `
  mutation CreateGroupInvite($groupName: String!, $playerEmail: String!) {
    createGroupInvite(groupName: $groupName, playerEmail: $playerEmail) {
      playerSub
      player {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
          }
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            player {
              sub
              username
              email
            }
          }
        }
        groups {
          items {
            playerSub
            player {
              sub
              username
              email
            }
            groupName
            group {
              name
              ownerSub
            }
          }
          nextToken
          startedAt
        }
      }
      groupName
      group {
        name
        ownerSub
        owner {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            distribution {
              one
              two
              three
              four
              five
              six
              misses
            }
            player {
              sub
              username
              email
            }
          }
          scoreHistory {
            nextToken
            startedAt
            items {
              playerSub
              number
              attemptNumber
              hardMode
            }
          }
          groups {
            items {
              playerSub
              groupName
            }
            nextToken
            startedAt
          }
        }
        scoresOverall {
          nextToken
          startedAt
          items {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            distribution {
              one
              two
              three
              four
              five
              six
              misses
            }
            player {
              sub
              username
              email
            }
          }
        }
        players {
          items {
            playerSub
            player {
              sub
              username
              email
            }
            groupName
            group {
              name
              ownerSub
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const acceptGroupInvite = /* GraphQL */ `
  mutation AcceptGroupInvite($groupName: String!) {
    acceptGroupInvite(groupName: $groupName) {
      name
      ownerSub
      owner {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
          }
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            player {
              sub
              username
              email
            }
          }
        }
        groups {
          items {
            playerSub
            player {
              sub
              username
              email
            }
            groupName
            group {
              name
              ownerSub
            }
          }
          nextToken
          startedAt
        }
      }
      scoresOverall {
        nextToken
        startedAt
        items {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
          }
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
        }
      }
      players {
        items {
          playerSub
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
          groupName
          group {
            name
            ownerSub
            owner {
              sub
              username
              email
            }
            scoresOverall {
              nextToken
              startedAt
            }
            players {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
