import React, { useContext, useEffect, useCallback, useState } from "react";
import { View, FlatList, Dimensions,} from "react-native";
import { ScoreDaily } from "../API";
import { PlayerScoreDaily } from "./PlayerScoreDaily";
import { Text, ThemeContext, ListItem, Divider } from "@rneui/themed";
import { useStyles } from "../styles";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";

const window = Dimensions.get("window");

export function PlayerScoreHistory({
  sub,
}: {
  sub: string | undefined;
}) {
  const { theme } = useContext(ThemeContext);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [nextToken, setNextToken] = useState<string | null>();
  const [scores, setScores] = useState<ScoreDaily[]>([]);
  const globalStyles = useStyles(theme);

  const updateScores = useCallback(async () => {
    if (sub !== undefined) {
      const result = await API.graphql({
        query: queries.listPlayerScores,
        variables: {sub: sub},
      }) as GraphQLResult<any>;
      const scoreConnection = result.data.listPlayerScores;
      setScores(scoreConnection?.items as ScoreDaily[]);
      setNextToken(scoreConnection?.nextToken);
    }
    
  }, [sub]);

  const concatScores = async () => {
    setIsFetching(true);
    if (nextToken !== null) {
      const result = (await API.graphql({
        query: queries.listPlayerScores,
        variables: {
          sub: sub,
          nextToken: nextToken,
        }
      })) as GraphQLResult<any>;
      setNextToken(result.data?.listPlayerScores.nextToken);
      setScores([
        ...scores,
        ...(result.data.listPlayerScores.items as ScoreDaily[]),
      ]);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    setNextToken(null);
    if (scores.length === 0) {
      updateScores();
    }
  }, [sub]);

  const Item = ({ score }: { score: ScoreDaily }) => (
    <ListItem>
      <ListItem.Title style={{maxWidth: 100}}>
        <View
          style={globalStyles.circle}
        >
          <Text h4 style={globalStyles.circleText}>
            {score.number}
          </Text>
        </View>
      </ListItem.Title>
      <ListItem.Content>
        <View style={{ alignSelf: "stretch", alignItems: "stretch", }}>
          <View style={{ flexDirection: "column" }}>
            <Text h3>
              Score: {score.attemptNumber || 7}/6{score.hardMode ? "*" : null}
            </Text>
          </View>
          <View style={{ position: "relative"}}>
            <View style={{ marginTop: 10, flex: 1 }}>
              <PlayerScoreDaily score={score.attempts} />
            </View>
          </View>
        </View>
      </ListItem.Content>
    </ListItem>
  );

  const renderItem = ({ item }: { item: ScoreDaily }) => <Item score={item} />;

  const FlatListItemSeparator = () => {
    return <Divider orientation="horizontal" color={theme.colors.grey3} />;
  };

  return (
    <View style={globalStyles.container}>
      <FlatList
        data={scores || []}
        renderItem={renderItem}
        keyExtractor={(item: any) => String(item.number)}
        onEndReached={() => concatScores()}
        onRefresh={() => updateScores()}
        refreshing={isFetching}
        style={{ flex: 1, width: "100%", maxWidth: 600, alignSelf: "center", margin: 10}}
        ItemSeparatorComponent={FlatListItemSeparator}
      ></FlatList>
    </View>
  );
}
