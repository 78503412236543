import React, { useState, useContext } from "react";
import { Button, ThemeContext, Input } from "@rneui/themed";
import {
  View,
  GestureResponderEvent,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
} from "react-native";
import * as mutations from "../../graphql/mutations";
import { StackScreenProps } from "@react-navigation/stack";
import { API, graphqlOperation } from "aws-amplify";
import { useStyles } from "../../styles";
import { NativeSyntheticEvent, TextInputChangeEventData } from "react-native";
import { GroupStackParamList } from "../../navigation/GroupsNavigator";

type Props = StackScreenProps<GroupStackParamList, "GroupPlayerInvite">;

export function GroupPlayerInvite({ navigation, route }: Props) {
  const { theme } = useContext(ThemeContext);
  const globalStyles = useStyles(theme);
  const [playerEmail, setPlayerEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  async function onSubmit(e: GestureResponderEvent) {
    e.preventDefault();
    try {
      const result = await API.graphql(
        graphqlOperation(mutations.createGroupInvite, {
          groupName: route.params.groupName,
          playerEmail: playerEmail,
        })
      );
      navigation.goBack();
    } catch (error: any) {
      console.log(error);
      setErrorMessage(`Error inviting player with email ${playerEmail} into group ${route.params.groupName}`);
    }
  }

  function handleChange(e: NativeSyntheticEvent<TextInputChangeEventData>) {
    const value = e.nativeEvent.text;
    setPlayerEmail(value);
  }

  function dismissKeyboard() { if (Platform.OS != "web"){ Keyboard.dismiss(); } }

  return (
    <TouchableWithoutFeedback onPress={dismissKeyboard} accessible={false}>
      <View style={[globalStyles.container, { width: "100%", marginTop: 10 }]}>
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            width: "98%",
            maxWidth: 500,
            marginTop: 10,
          }}
        >
          <Input
            style={globalStyles.input}
            label="Player Email"
            renderErrorMessage={errorMessage != "" ? true : false}
            errorMessage={errorMessage}
            onChange={(e: any) => handleChange(e)}
            value={playerEmail}
          />
          <Button onPress={(e: GestureResponderEvent) => onSubmit(e)}>
            Submit
          </Button>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}
