import React, { useState, useEffect, useContext } from "react";
import { Text, Button, ThemeContext } from "@rneui/themed";
import {
  View,
  StyleSheet,
  ScrollView,
  GestureResponderEvent,
} from "react-native";
import * as model from "../../API";
import * as mutations from "../../graphql/mutations";
import { StackScreenProps } from "@react-navigation/stack";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import { PlayerContext } from "../../contexts";
import { ProfileStackParamList } from "../../navigation/ProfileNavigator";
import * as steps from "../../components/ScoresManualSteps";
import { useStyles } from "../../styles";
import { ScoreManualStyles } from "../../styles/ScoreManual";
import { NativeSyntheticEvent, TextInputChangeEventData } from "react-native";

type ErrorState = {
  state: boolean;
  description: string;
};

type Errors = {
  rounds?: ErrorState;
  currentStreak?: ErrorState;
  maxStreak?: ErrorState;
  one?: ErrorState;
  two?: ErrorState;
  three?: ErrorState;
  four?: ErrorState;
  five?: ErrorState;
  six?: ErrorState;
  misses?: ErrorState;
};

let defaultScore: model.ScoreInput = {
  playerSub: "",
  date: new Date().toISOString().substring(0, 10),
  maxStreak: 0,
  currentStreak: 0,
  rounds: 0,
  distribution: {
    one: 0,
    two: 0,
    three: 0,
    four: 0,
    five: 0,
    six: 0,
  },
};

type Props = StackScreenProps<ProfileStackParamList, "ScoreManual">;

export function ScoreManual({ navigation, route }: Props) {
  const playerDetails = React.useContext(PlayerContext);
  const { theme } = useContext(ThemeContext);
  const globalStyles = useStyles(theme);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<Errors>({
    rounds: { state: false, description: "" },
    currentStreak: { state: false, description: "" },
    maxStreak: { state: false, description: "" },
    one: { state: false, description: "" },
    two: { state: false, description: "" },
    three: { state: false, description: "" },
    four: { state: false, description: "" },
    five: { state: false, description: "" },
    six: { state: false, description: "" },
    misses: { state: false, description: "" },
  });
  const [startDate, setStartDate] = useState<string>((new Date).toISOString().substring(0, 10));
  const [score, setScore] = useState<model.ScoreInput>(defaultScore);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [step, setStep] = useState<number>(0);
  const [misses, setMisses] = useState<number>(0);

  useEffect(() => {
    setScore({ ...score, playerSub: playerDetails?.player?.sub || "" });
  }, [playerDetails, errors]);

  // go back to previous step
  const prevStep = () => {
    setStep(step - 1);
  };

  // proceed to the next step
  const nextStep = () => {
    switch (step) {
      case 1:
        break;
      case 2:
        if (score.rounds === 0) {
          setErrors({
            ...errors,
            ...{
              rounds: {
                state: true,
                description: "Must be a postive number",
              } as ErrorState,
            },
          });
          return;
        }
        if (score.currentStreak === 0) {
          setErrors({
            ...errors,
            ...{
              currentStreak: {
                state: true,
                description: "Must be a postive number",
              } as ErrorState,
            },
          });
          return;
        }
        if (score.maxStreak === 0) {
          setErrors({
            ...errors,
            ...{
              maxStreak: {
                state: true,
                description: "Must be a postive number",
              } as ErrorState,
            },
          });
          return;
        }
        break;
      case 3:
        if (misses < 0) {
          setErrors({
            ...errors,
            ...{
              misses: {
                state: true,
                description: "This number cannot be negative",
              } as ErrorState,
            },
          });
          return;
        }
        break;
      case 4:
        break;
    }
    setStep(step + 1);
  };

  async function onSubmit(e: GestureResponderEvent) {
    e.preventDefault();
    setSubmitting(true);
    try {
      const result = await API.graphql(
        graphqlOperation(mutations.createScoreManual, {
          score: score,
        })
      );
      setScore(defaultScore);
      navigation.goBack();
    } catch (error: any) {
      if (error.errors !== undefined) {
        if (error.errors[0].message.includes("ConditionalCheckFailed")) {
          setErrorMessage("You have already set your score manually");
        } else {
          setErrorMessage(error.errors[0].message);
        }
      }
    }
    setSubmitting(false);
  }

  function handleChange(
    e: NativeSyntheticEvent<TextInputChangeEventData>,
    name: string
  ) {
    const value = e.nativeEvent.text;
    const numValue = Number(value);
    if (isNaN(numValue)) {
      setErrors({
        ...errors,
        [name]: {
          state: true,
          description: "Must be a postive number",
        } as ErrorState,
      });
      return;
    } else {
      setErrors({
        ...errors,
        [name]: { state: false, description: "" } as ErrorState,
      });
    }
    const distributionNames = ["one", "two", "three", "four", "five", "six"];

    if (distributionNames.indexOf(name) > -1) {
      const setDist: model.DistributionInput = score.distribution;
      // @ts-ignore
      setDist[name] = numValue;
      setScore({ ...score, ...{ distribution: setDist } });
    } else {
      setScore({ ...score, [name]: numValue });
    }

    setMisses(
      score.rounds -
        (score.distribution.one +
          score.distribution.two +
          score.distribution.three +
          score.distribution.four +
          score.distribution.five +
          score.distribution.six)
    );
  }

  function renderStep() {
    switch (step) {
      case 0:
        return (
          <>
            <View style={ScoreManualStyles.stepHeader}>
              <Text h2>Manual Score</Text>
            </View>
            <View style={ScoreManualStyles.stepContent}>
              <Text>
                This form will set your initial score. Use this functionality
                with caution as it will change your current score
              </Text>
            </View>
            <View style={ScoreManualStyles.stepFooter}>
              <Button onPress={(e) => nextStep()}>Start</Button>
            </View>
          </>
        );
      case 1:
        return (
          <steps.DateStep
            setStartDate={setStartDate}
            startDate={startDate}
            nextStep={nextStep}
          />
        );
      case 2:
        return (
          <steps.StatisticsStep
            score={score}
            handleChange={handleChange}
            errors={errors}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 3:
        return (
          <steps.DistributionStep
            score={score}
            handleChange={handleChange}
            misses={misses}
            errors={errors}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 4:
        return (
          <steps.ReviewStep
            score={score}
            misses={misses}
            onSubmit={onSubmit}
            prevStep={prevStep}
          />
        );
    }
  }

  return (
    <View style={[globalStyles.container, {width: "100%"}]}>
      {errorMessage !== "" ? (
        <View>
          <Text>{errorMessage}</Text>
        </View>
      ) : (
        ""
      )}
      <View style={{flex: 1, flexDirection: "row", width: "98%", maxWidth: 500}}>
        <ScrollView contentContainerStyle={{ flexGrow: 1, width: "100%" }}>
          {renderStep()}
        </ScrollView>
      </View>
    </View>
  );
}
