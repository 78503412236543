/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGroup = /* GraphQL */ `
  query GetGroup($name: String!) {
    getGroup(name: $name) {
      name
      ownerSub
      owner {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
          }
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            player {
              sub
              username
              email
            }
          }
        }
        groups {
          items {
            playerSub
            player {
              sub
              username
              email
            }
            groupName
            group {
              name
              ownerSub
            }
          }
          nextToken
          startedAt
        }
      }
      scoresOverall {
        nextToken
        startedAt
        items {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
          }
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
        }
      }
      players {
        items {
          playerSub
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
          groupName
          group {
            name
            ownerSub
            owner {
              sub
              username
              email
            }
            scoresOverall {
              nextToken
              startedAt
            }
            players {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const getPlayer = /* GraphQL */ `
  query GetPlayer($sub: String!) {
    getPlayer(sub: $sub) {
      sub
      username
      email
      scoreOverall {
        playerSub
        number
        average
        rounds
        currentStreak
        maxStreak
        distribution {
          one
          two
          three
          four
          five
          six
          misses
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            distribution {
              one
              two
              three
              four
              five
              six
              misses
            }
            player {
              sub
              username
              email
            }
          }
          scoreHistory {
            nextToken
            startedAt
            items {
              playerSub
              number
              attemptNumber
              hardMode
            }
          }
          groups {
            items {
              playerSub
              groupName
            }
            nextToken
            startedAt
          }
        }
      }
      scoreHistory {
        nextToken
        startedAt
        items {
          playerSub
          number
          attemptNumber
          hardMode
          attempts {
            one {
              one
              two
              three
              four
              five
            }
            two {
              one
              two
              three
              four
              five
            }
            three {
              one
              two
              three
              four
              five
            }
            four {
              one
              two
              three
              four
              five
            }
            five {
              one
              two
              three
              four
              five
            }
            six {
              one
              two
              three
              four
              five
            }
          }
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
        }
      }
      groups {
        items {
          playerSub
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
          groupName
          group {
            name
            ownerSub
            owner {
              sub
              username
              email
            }
            scoresOverall {
              nextToken
              startedAt
            }
            players {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listGroupPosts = /* GraphQL */ `
  query ListGroupPosts($groupName: String!, $nextToken: String) {
    listGroupPosts(groupName: $groupName, nextToken: $nextToken) {
      items {
        postId
        groupName
        playerSub
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            distribution {
              one
              two
              three
              four
              five
              six
              misses
            }
            player {
              sub
              username
              email
            }
          }
          scoreHistory {
            nextToken
            startedAt
            items {
              playerSub
              number
              attemptNumber
              hardMode
            }
          }
          groups {
            items {
              playerSub
              groupName
            }
            nextToken
            startedAt
          }
        }
        contentType
        content
        createdAt
      }
      nextToken
    }
  }
`;
export const listGroupScoresMonthly = /* GraphQL */ `
  query ListGroupScoresMonthly(
    $groupName: String!
    $month: String!
    $nextToken: String
  ) {
    listGroupScoresMonthly(
      groupName: $groupName
      month: $month
      nextToken: $nextToken
    ) {
      items {
        groupName
        playerSub
        month
        averageOverall
        averagePosted
        rounds
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            distribution {
              one
              two
              three
              four
              five
              six
              misses
            }
            player {
              sub
              username
              email
            }
          }
          scoreHistory {
            nextToken
            startedAt
            items {
              playerSub
              number
              attemptNumber
              hardMode
            }
          }
          groups {
            items {
              playerSub
              groupName
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const listPlayerScores = /* GraphQL */ `
  query ListPlayerScores($sub: String!, $nextToken: String) {
    listPlayerScores(sub: $sub, nextToken: $nextToken) {
      nextToken
      startedAt
      items {
        playerSub
        number
        attemptNumber
        hardMode
        attempts {
          one {
            one
            two
            three
            four
            five
          }
          two {
            one
            two
            three
            four
            five
          }
          three {
            one
            two
            three
            four
            five
          }
          four {
            one
            two
            three
            four
            five
          }
          five {
            one
            two
            three
            four
            five
          }
          six {
            one
            two
            three
            four
            five
          }
        }
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            distribution {
              one
              two
              three
              four
              five
              six
              misses
            }
            player {
              sub
              username
              email
            }
          }
          scoreHistory {
            nextToken
            startedAt
            items {
              playerSub
              number
              attemptNumber
              hardMode
            }
          }
          groups {
            items {
              playerSub
              groupName
            }
            nextToken
            startedAt
          }
        }
      }
    }
  }
`;
export const listGroupInvites = /* GraphQL */ `
  query ListGroupInvites($groupName: String!) {
    listGroupInvites(groupName: $groupName) {
      items {
        playerSub
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            distribution {
              one
              two
              three
              four
              five
              six
              misses
            }
            player {
              sub
              username
              email
            }
          }
          scoreHistory {
            nextToken
            startedAt
            items {
              playerSub
              number
              attemptNumber
              hardMode
            }
          }
          groups {
            items {
              playerSub
              groupName
            }
            nextToken
            startedAt
          }
        }
        groupName
        group {
          name
          ownerSub
          owner {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
          scoresOverall {
            nextToken
            startedAt
            items {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
          }
          players {
            items {
              playerSub
              groupName
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const listPlayerInvites = /* GraphQL */ `
  query ListPlayerInvites {
    listPlayerInvites {
      items {
        playerSub
        player {
          sub
          username
          email
          scoreOverall {
            playerSub
            number
            average
            rounds
            currentStreak
            maxStreak
            distribution {
              one
              two
              three
              four
              five
              six
              misses
            }
            player {
              sub
              username
              email
            }
          }
          scoreHistory {
            nextToken
            startedAt
            items {
              playerSub
              number
              attemptNumber
              hardMode
            }
          }
          groups {
            items {
              playerSub
              groupName
            }
            nextToken
            startedAt
          }
        }
        groupName
        group {
          name
          ownerSub
          owner {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
          scoresOverall {
            nextToken
            startedAt
            items {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
          }
          players {
            items {
              playerSub
              groupName
            }
            nextToken
            startedAt
          }
        }
      }
      nextToken
    }
  }
`;
