import React, { useContext, useEffect } from "react";
import { View, StyleSheet, FlatList, Platform } from "react-native";
import { Auth } from "aws-amplify";
import { PlayerContext } from "../../contexts";
import { StackScreenProps } from "@react-navigation/stack";
import { Text, ListItem, Button, useThemeMode, ThemeContext, ThemeMode, Divider } from "@rneui/themed";
import { useStyles } from "../../styles";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ProfileStackParamList } from "../../navigation/ProfileNavigator";

type Props = StackScreenProps<ProfileStackParamList, "Profile">;

export function Profile({ navigation, route }: Props) {
  const { mode, setMode } = useThemeMode();
  const playerDetails = React.useContext(PlayerContext);
  const { theme } = useContext(ThemeContext);
  const globalStyles = useStyles(theme);
  const [items, setItems] = React.useState<any[]>([]);

  async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  async function switchThemeMode() {
    let newMode: ThemeMode = 'light';
    if (mode === 'light') {
      newMode = 'dark';
    }
    try {
      await AsyncStorage.setItem('@theme_mode', newMode.toString())
      setMode(newMode);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    const i: any[] = [
      {
        title: "Username",
        value: playerDetails?.player?.username,
        onPress: function() {navigation.navigate("UserName")}
      },
      {
        title: "Manual Score",
        value: "",
        onPress: function() {navigation.navigate("ScoreManual")}
      }
    ];
    console.log(Platform.OS);
    if (Platform.OS !== 'web') {
      i.push(
        {
          title: "Notifications",
          value: "",
          onPress: function() {navigation.navigate("Notifications")}
        }
      );
    }

    setItems(i);
  }, []);

  const FlatListItemSeparator = () => {
    return <Divider orientation="horizontal" color={theme.colors.grey3} />;
  };

  const renderItem = ({ item }: { item: any }) => {
    return (
      <ListItem onPress={() => {item.onPress()}}>
          <ListItem.Title>
            <Text>{item.title}</Text>
          </ListItem.Title>
          <ListItem.Content>
            <Text style={{alignSelf: "flex-end", }}>{item.value}</Text>
          </ListItem.Content>
          <ListItem.Chevron></ListItem.Chevron>
      </ListItem>
    );
  }

  return (
    <View style={globalStyles.container} >
      <FlatList
        data={items || []}
        renderItem={renderItem}
        keyExtractor={(item: any) => String(item.title)}
        style={{
          flex: 1,
          alignSelf: "center",
          width: "100%",
          maxWidth: 600,
          margin: 10,
        }}
        ItemSeparatorComponent={FlatListItemSeparator}
      ></FlatList>

      <View style={styles.containerFooter}>
        <Button onPress={() => switchThemeMode()} title={mode.toString()}/>
        <Button title="Sign Out" containerStyle={{width: "100%"}} onPress={(e) => signOut()} color="error"/>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  containerSettings: {
    flexDirection: "row",
    margin: 5,
    height: 40,
    padding: 5,
    borderRadius: 5,
    alignItems: "center",
  },
  textSettingsLabel: {
    textAlign: "left",
  },
  textSettingsValue: {
    flex: 3,
    textAlign: "right",
    marginRight: 10,
  },
  listItem: {
    flexGrow: 1,
  },
  containerFooter: {
    flex: 1,
    justifyContent: "flex-end",
    width: "90%",
    maxWidth: 500
  },
  containerHeader: {
    flex: 1,
  },
});
