import React, { useState, useEffect } from "react";
import { Group, GroupPlayerScoreMonthly } from "../API";
import { Auth, API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore

interface GroupContextInterface {
  group: Group | undefined;
  updateGroup: (groupName: string) => Promise<void>;
}

const GroupContext = React.createContext<GroupContextInterface | null>(null);

function GroupProvider({ children }: { children: any }) {
  const [group, setGroup] = useState<Group>();

  const updateGroup = async(groupName: string) => {
    const result = (await API.graphql(
      graphqlOperation(queries.getGroup, {
        name: groupName,
      })
    )) as GraphQLResult<any>;
    setGroup(result.data.getGroup as Group);
  }

  return (
    <GroupContext.Provider value={{group, updateGroup}}>{children}</GroupContext.Provider>
  );
}

export { GroupContext, GroupProvider   };
