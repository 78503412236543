import React, { useEffect } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { View } from "react-native";
import { AppNavigator } from "./AppNavigator";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useTheme, ThemeMode, useThemeMode } from "@rneui/themed";
import AsyncStorage from '@react-native-async-storage/async-storage';

export type RootStackParamList = {
  BottomTabNavigatorScreen: undefined;
  PostScore: undefined;
};

const RootStack = createNativeStackNavigator<RootStackParamList>();

export function RootNavigator() {
  const { theme } = useTheme();
  const { mode, setMode } = useThemeMode();

  const setThemeMode = async () => {
    try {
      const theme_mode = await AsyncStorage.getItem('@theme_mode') as ThemeMode;
      if (mode !== theme_mode) {
        if(theme_mode !== null) {
          setMode(theme_mode);
        } else {
          try {
            await AsyncStorage.setItem('@theme_mode', "light")
          } catch (e) {
            console.log(e);
          }
        }
      }
    } catch(e) {
      console.log(e);
    }
  }    

  useEffect(() => {
    setThemeMode();
  }, [theme]);

  return (
    <View style={{ flex: 1, backgroundColor: theme.colors.background }}>
      <NavigationContainer theme={{
        dark: theme.mode === 'dark' ? true : false,
        colors: {
          primary: theme.colors.black,
          background: theme.colors.background,
          card: theme.colors.grey5,
          text: theme.colors.black,
          border: theme.colors.grey5,
          notification: theme.colors.warning,
        }
      }}>
        <AppNavigator />
      </NavigationContainer>
    </View>
  );
}
