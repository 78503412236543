/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateGroupPost = /* GraphQL */ `
  subscription OnCreateGroupPost {
    onCreateGroupPost {
      postId
      groupName
      playerSub
      player {
        sub
        username
        email
        scoreOverall {
          playerSub
          number
          average
          rounds
          currentStreak
          maxStreak
          distribution {
            one
            two
            three
            four
            five
            six
            misses
          }
          player {
            sub
            username
            email
            scoreOverall {
              playerSub
              number
              average
              rounds
              currentStreak
              maxStreak
            }
            scoreHistory {
              nextToken
              startedAt
            }
            groups {
              nextToken
              startedAt
            }
          }
        }
        scoreHistory {
          nextToken
          startedAt
          items {
            playerSub
            number
            attemptNumber
            hardMode
            player {
              sub
              username
              email
            }
          }
        }
        groups {
          items {
            playerSub
            player {
              sub
              username
              email
            }
            groupName
            group {
              name
              ownerSub
            }
          }
          nextToken
          startedAt
        }
      }
      contentType
      content
      createdAt
    }
  }
`;
