import React, { useState } from "react";
import {
  View,
  StyleSheet,
  NativeSyntheticEvent,
  TextInputChangeEventData,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
} from "react-native";
import { Input, Button } from "@rneui/themed";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import { PlayerContext } from "../../contexts";
import { RootStackParamList } from "../../navigation/";
import { StackScreenProps } from "@react-navigation/stack";

type Props = StackScreenProps<RootStackParamList, "PostScore">;

export function PostScore({ navigation, route }: Props) {
  const [processing, setProcessing] = useState<boolean>(false);
  const [scoreNew, setScoreNew] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const playerDetails = React.useContext(PlayerContext);

  function onScoreInputChange(
    e: NativeSyntheticEvent<TextInputChangeEventData>
  ) {
    setScoreNew(e.nativeEvent.text);
  }

  async function onSubmit() {
    setProcessing(true);
    try {
      await API.graphql(
        graphqlOperation(mutations.createScore, {
          score: scoreNew,
        })
      );
      await playerDetails?.updatePlayer();
      setScoreNew("");
      navigation.goBack();
    } catch (error: any) {
      if (error.errors !== undefined) {
        setErrorMessage(error.errors[0].message);
      }
    }
    setProcessing(false);
  }

  function dismissKeyboard() { if (Platform.OS != "web"){ Keyboard.dismiss(); } }

  return (
    <TouchableWithoutFeedback onPress={dismissKeyboard} accessible={false}>
      <View style={styles.container}>
        <Input
          value={scoreNew}
          label="Post a Score"
          placeholder="Score"
          numberOfLines={10}
          multiline={true}
          inputStyle={{ height: 200 }}
          containerStyle={{ maxWidth: 300 }}
          onChange={(e) => onScoreInputChange(e)}
          errorMessage={errorMessage}
          renderErrorMessage={true}
        />
        <Button loading={processing} onPress={() => onSubmit()}>
          Submit
        </Button>
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 30,
  },
  containerError: {
    margin: 10,
  },
  formLabel: {
    fontSize: 20,
    fontWeight: "bold",
  },
  scoreInput: {
    margin: 20,
    height: 200,
    width: 200,
    paddingHorizontal: 10,
  },
  scoreInputContainer: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  submitButton: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    backgroundColor: "#3F5EFB",
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    width: 200,
    elevation: 4,
    borderRadius: 8,
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 80,
  },
  submitText: {
    fontSize: 16,
    width: 100,
    textAlign: "center",
    fontWeight: "bold",
    color: "white",
  },
});
